function App() {
	return (
		<main className="bg-[#121619] min-h-screen p-12">
			<div className="max-w-screen-xl mx-auto flex flex-col items-center">
				<section className="flex flex-col items-center p-6 my-6">
					<h1 className="text-2xl md:text-4xl text-white">
						⚔️ Guild of <span className="text-[#e5c687] font-bold">Gains</span> 💪
					</h1>
					<p className="text-white text-center text-xl mt-8">
						Where every workout is an epic quest, and your fitness journey becomes the ultimate adventure.
					</p>
           <p className="text-white text-lg my-6">Expected launch on New Years, 50% discount for anyone that pre-registers through the email form before the launch</p>
					<div class="klaviyo-form-SUrDv2"></div>
				</section>
				<section className="flex flex-col md:flex-row items-center gap-6 my-6">
					<div className="">
						<p className="text-2xl text-[#09814A] font-semibold text-center mb-4">Train</p>
						<p className="text-white text-lg text-center">
							🏋️‍♂️ Transform your body, conquer challenges, and become the hero of your own fitness journey!
						</p>
					</div>
					<div className="md:col-start-2 md:row-start-2">
						<p className="text-2xl text-[#09814A] font-semibold text-center mb-4">Track</p>
						<p className="text-white text-lg text-center">
							⚔️ Battle laziness, defeat the boss of bad habits, and watch your character grow stronger with every rep.
						</p>
					</div>
					<div className="md:col-start-3 md:row-start-3">
						<p className="text-2xl text-[#09814A] font-semibold text-center mb-4">Community</p>
						<p className="text-white text-lg text-center">🏆 Join players turning sweat into XP and muscles into armor.</p>
					</div>
				</section>
        <div>
       
        </div>
			</div>
		</main>
	);
}

export default App;
